import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import { ImgixGatsbyImage } from '@imgix/gatsby'

import Seo from "../components/seo.js"

const BlogPage = ({ data, location, pageContext }) => {

  return (
        <Layout>
        <Seo
          pagetitle="ブログ"
          pagedesc="ESSENTIALS のブログです"
          pagepath={location.pathname}
        />
          <section className="content bloglist">
            <div className="container">
              <h1 className="bar">RECENT POSTS</h1>
              <div className="posts">
                {data.allMicrocmsBlog.edges.map(( { node } ) => (
                  <article className="post" key={node.id}>
                    <Link to={`/blog/post/${node.slug}/`}>
                      <figure>
                      <ImgixGatsbyImage
                        src={node.eyecatch.url}
                        imgixParams={{ auto: ["format", "compress"] }}
                        layout="constrained"
                        width={573}
                        sourceWidth={node.eyecatch.width}
                        sourceHeight={node.eyecatch.height}
                        style={{ height: "100%" }}
                        />
                      </figure>
                      <h3>{node.title}</h3>
                    </Link>
                  </article>
                ))}
              </div>

              <ul className="pagenation">
                {!pageContext.isFirst && (
                  <li className="prev">
                    <Link to={
                      pageContext.currentPage === 2
                        ? `/blog/`
                        : `/blog/${pageContext.currentPage - 1}/`
                      }
                      rel="prev">
                      <i className="fas fa-chevron-left" />
                      <span>前のページ</span>
                    </Link>
                  </li>
                )}
                {!pageContext.isLast && (
                  <li className="next">
                    <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
                      <span>次のページ</span>
                      <i className="fas fa-chevron-right" />
                    </Link>
                  </li>
                )}
              </ul>

            </div>
          </section>
        </Layout>
  )
}

export const query = graphql`
query($skip: Int!, $limit: Int!) {
  allMicrocmsBlog(sort: {order: DESC, fields: publishDate},
    skip: $skip,
    limit: $limit,
    ) {
    edges {
      node {
        title
        id
        slug
        eyecatch {
          height
          url
          width
        }
      }
    }
  }
}`

export default BlogPage